import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Wohnbauforderung = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Alles rund um die Wohnbauförderung" showCalc={false} />
            <Article>
                <p>
                    Ein Privileg des österreichischen Sozialstaats ist und bleibt seit mehr als 50 Jahren die
                    Wohnbauförderung. Hauskauf, Neubau, Umbau oder Sanierung sind kostenintensive Investitionen. Um da
                    die Finanzierung zu erleichtern, lohnt es sich, einen Antrag auf Wohnbauförderung zu stellen. Aber
                    wer bekommt überhaupt Förderung und wie viel? Muss ich diese zurückzahlen und was passiert, wenn ich
                    mein gefördertes Haus doch verkaufen möchte? Auf diese und noch mehr Fragen zur Wohnbauförderungen
                    gibt es hier die Antworten.
                </p>
                <p>
                    Du spielst auch mit dem Gedanken, dir einen{" "}
                    <Link to="/artikel/wohnkredit/" target="_blank" rel="noreferrer noopener">
                        Wohnkredit
                    </Link>{" "}
                    zu holen? Dann probier gleich unseren{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechner
                    </Link>{" "}
                    aus.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Was ist Wohnbauförderung?</h2>
                <p>
                    Wohnbauförderung ist ein vom Staat gebilligter Zuschuss für die Schaffung von Wohnraum, der
                    üblicherweise Privatpersonen gebilligt wird. Unter Schaffung von Wohnraum fallen dabei verschiedene
                    Bauprojekte, wie etwa: Neubau, Sanierung, Renovierung, Umbau oder Zubau.
                </p>
                <p>
                    Wohnbauförderung ist dabei Ländersache. Daher kann man gar nicht über die Wohnbauförderung in
                    Österreich sprechen. Regelungen en detail sind jeweils länderspezifisch. Dennoch gibt es gewisse
                    grundsätzliche Charakteristika, die einheitlich gelten.
                </p>
                <hr />

                <h2>Wer bekommt‘s?</h2>
                <p>Dazu gehört, was jemand auf jeden Fall erfüllen muss, um eine Förderung zu erhalten.</p>
                <p>
                    Der Antragsteller muss zu allererst volljährig sein und eine österreichische Staatsbürgerschaft oder
                    eine gleichgestellte (z.B. aus EU-Ländern, Schweiz, Liechtenstein) vorweisen können.
                </p>
                <p>
                    Wird eine gewisse Netto-Einkommensgrenze im Haushalt überschritten oder ist keine
                    (Mit-)Eigentümerschaft der Liegenschaft oder zumindest Baugenehmigung gegeben, wird die
                    Wohnbauförderung in der Regel nicht gänzlich bewilligt. Welche Einkommensgrenze hier geltend gemacht
                    wird, ist von Bundesland zu Bundesland verschieden. Liegt sie in Wien bei einem zwei Personen
                    Haushalt beispielsweise bei 83.600 Euro Nettojahreseinkommen, sind es in Oberösterreich 65.000 Euro.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Für welche Immobilien kommt Wohnbauförderung in Frage?</h2>
                <p>
                    Des Weiteren gilt: Die Immobilie, die gefördert gekauft, gebaut oder saniert wird muss als
                    Hauptwohnsitz gemeldet sein und das ganze Jahr über bewohnt werden. Stichprobenartige Kontrollen von
                    Landesbeamt*innen, um die „ständige Nutzung“ sicherzustellen, sind hier keine Seltenheit. Ferien
                    oder Wochenendhäuser sind von der Wohnbauförderung ausgeschlossen. Wird ein gefördert erworbenes
                    neues Eigenheim bezogen, ist es in allen Bundesländern notwendig, die vorher bezogene Immobilie
                    aufzugeben.
                </p>
                <p>
                    Für eine erfolgreiche Antragstellung ist es immer förderlich, umweltschonendes Wohnen ermöglichende
                    Bauprojekte anzuschlagen. Seit 2022 gelten österreichweit verschärfte Regeln: Soll für eine
                    Immobilie Wohnbauförderung bewilligt werden, dürfen in dieser keine fossilen Brennstoffe eingesetzt
                    werden.
                </p>
                <hr />

                <h2>Was bekomme ich?</h2>
                <p>
                    Es gibt verschiedene Arten der Wohnbauförderung. Der Zuschuss kann zum einen als einmalige Zahlung
                    ausgegeben werden, die nicht zurückzuerstatten ist. Manche Bundesländer vergeben Zinszuschüsse, um
                    für die Rückzahlung eines bereits bestehenden{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredits
                    </Link>{" "}
                    aufzukommen, andere wieder vergeben selbst Darlehen zu günstigen{" "}
                    <Link to="/artikel/konditionen/" target="_blank" rel="noreferrer noopener">
                        Konditionen
                    </Link>
                    . Auch möglich ist die Übernahme einer Bürgschaft. Art und Höhe der gewährten Förderung sind dabei
                    bundeslandspezifisch und konkret erst über Antragstellung in Erfahrung zu bringen.
                </p>
                <p>
                    Grundsätzlich gilt: Entscheidend über Höhe des Zuschusses ist zuallererst die Einkommenssituation
                    des Haushalts. In aller Regel wird als Ermessensgrundlage hier das Haushaltseinkommen des Vorjahres
                    herangezogen – bei Angestellten in Form des Jahreslohnzettels, bei Selbstständigen über dein
                    Einkommenssteuerbescheid des Vorjahres. Staatliche Zuschüsse wie Studienbeihilfe oder Pflegegeld
                    fallen demnach aus dieser Kalkulation raus. Mit der Anzahl der im Haushalt lebenden Personen steigt
                    in der Regel nicht nur die Jahresnettoeinkommensgrenze, sondern auch der generelle Anspruch auf
                    Förderung. Umweltschonendes, energieeffizientes Bauen wird üblicherweise höher gefördert.
                </p>
                <p>
                    Wird die Einkommensgrenze überschritten, bedeutet das übrigens nicht das Aus für jegliche staatliche
                    Förderung für Arbeiten am Haus. In vielen Bundesländern werden energieeffiziente Sanierungsarbeiten
                    oder die Realisierung von erneuerbaren Energieanlagen auch so bezuschusst.
                </p>
                <hr />

                <h2>Etwas vergessen? – good to know!</h2>
                <p>
                    Möchte man die Finanzierung seines Neubauprojektes mit Zuschüssen aus der Wohnbauförderung
                    aufbessern, sollte man nicht vergessen, dass diese vor Baubeginn gewilligt werden muss. Bei einer
                    durchschnittlichen Bearbeitungszeit von Anträgen von bis zu sechs Monaten bedarf es hier einiger
                    Vorlaufzeit.
                </p>
                <p>
                    Wer über die Wohnbauförderung baut, muss außerdem mit dem im Grundbuch eingetragenem
                    Veräußerungsverbot rechnen. Zwar ist es in Ausnahmefällen möglich, in Absprache mit dem Land zu
                    einer Einigung über die Veräußerung der Immobilie zu kommen. Das ist aber kein Regelfall.
                    Wohnbauförderung in Anspruch zu nehmen stellt damit auch eine gewisse Bindung an die Immobilie dar.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Fazit</h2>
                <p>
                    Die Wohnbauförderung ist ein zu Recht beliebtes Mittel bei der Wohntraumfinanzierung und ein gutes
                    sozialstaatliches Instrument zur Gewährleistung von leistbaren Wohnraum mit Qualität, welches
                    gleichzeitig die ökologische Transformation mit vorantreibt.
                </p>
                <p>
                    Dabei setzt sie nur schon bei Antragstellung eine bestehende Finanzierung des Eigenheimprojekts
                    voraus, welche dann nachträglich bezuschusst wird.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"wohnbauforderung"}></BreadcrumbList>
            <ArticleStructuredData page={"wohnbauforderung"} heading={"Alles rund um die Wohnbauförderung"} />
        </Layout>
    );
};

export default Wohnbauforderung;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.wohnbauforderung", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
